#white {
    color: white;
}

.page-header {
    min-height: 200px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-size: calc(15px + 2vmin);
    color: whitesmoke;
    z-index: 1;
    position: relative;
    -webkit-text-stroke: 1.5px grey;
    background-color: white;
    box-shadow: rgba(45, 35, 66, 0.4) 0 2px 4px, rgba(45, 35, 66, 0.3) 0 7px 13px 0px, #D6D6E7 0 0px 0 inset;
    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='28' height='49' viewBox='0 0 28 49'%3E%3Cg fill-rule='evenodd'%3E%3Cg id='hexagons' fill='%23f2f2f2' fill-opacity='0.49' fill-rule='nonzero'%3E%3Cpath d='M13.99 9.25l13 7.5v15l-13 7.5L1 31.75v-15l12.99-7.5zM3 17.9v12.7l10.99 6.34 11-6.35V17.9l-11-6.34L3 17.9zM0 15l12.98-7.5V0h-2v6.35L0 12.69v2.3zm0 18.5L12.98 41v8h-2v-6.85L0 35.81v-2.3zM15 0v7.5L27.99 15H28v-2.31h-.01L17 6.35V0h-2zm0 49v-8l12.99-7.5H28v2.31h-.01L17 42.15V49h-2z'/%3E%3C/g%3E%3C/g%3E%3C/svg%3E");
    text-shadow: -2px 2px 2px rgba(45, 35, 66, 0.5);
}

.page-content {
    -webkit-box-flex: 1;
    flex-grow: 1;
}

.page {
    background-color: whitesmoke;
}

.side-panel {
    position: fixed;
    top: 0;
    left: 1%;
    z-index: 2;
}

.image {
    width: calc(100%/2);
    height: calc(100%/2);
    display: block;
    margin: 0 auto;
    margin-top: 15px;
}

.image-fit {
    width: 100%;
    height: auto;
}

.icons {
    border-radius: 50%;
}

.icons_parent {
    padding-bottom: 10px;
    margin: 0 auto;
    position: absolute;
    right: 2%;
}

.vertical-timeline-element {
    z-index: 1;
}

#background-image-bottom-left {
    position: absolute;
    top: calc(100% - 500px - 50px);
    left: calc(100% - (70vw));
    opacity: 0.5;
}

#background-image-lower-right {
    position: absolute;
    top: calc(50% - 500px - 50px);
    left: calc(100% - (25vw));
    opacity: 0.5;
    -webkit-transform: scaleX(-1);
    transform: scaleX(-1);
}

#background-image-middle-left {
    position: absolute;
    top: calc(500px - 50px);
    left: calc(100% - (70vw));
    opacity: 0.5;
}

h3 {
    display: block;
    font-size: 1.17em;
    margin-block-start: 1em;
    margin-block-end: 1em;
    margin-inline-start: 0px;
    margin-inline-end: 0px;
    font-weight: bold;
}

h4 {
    display: block;
    margin-block-start: 1.33em;
    margin-block-end: 1.33em;
    margin-inline-start: 0px;
    margin-inline-end: 0px;
    font-weight: 500;
    font-size: 1em;
}

p {
    font-weight: 400 !important;
    color: grey;
}