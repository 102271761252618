.astext {
    background:none;
    border:none;
    margin:0;
    padding:0;
    cursor: pointer;
}

.offcanvas-end{
    width:60%;
    min-width: 350px;
}

h1{
    font-size: 2em;
    font-weight: 500;
  }