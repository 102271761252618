.button-style-1{
    margin-top: 5px;
    width: 50px;
    height: 50px;
    background-color: whitesmoke;
}

.button-style-image{
    width: 25px;
    height: 25px;
}

.button-style-smart{
    width: 100%;
    height: auto;
    background-color: whitesmoke;
    border-color: red;
    color: grey;
    transition: all .2s ease-in-out;
}

.button-style-smart:hover{
    width: 100%;
    height: auto;
    background-color: whitesmoke;
    border-color: red;
    color: grey;
    transform: scale(1.05);
}

.button-text{
    justify-content: center;
    padding-top: 5px;
}